import { PropsWithChildren } from 'react'

import { classNames } from '@/modules/shared/utils/classNames'

interface BadgeIconProps extends PropsWithChildren {
  isBadged: boolean
  className?: string
}

export const BadgeIcon = ({ children, isBadged, className }: BadgeIconProps) => {
  return (
    <div className="relative">
      {children}
      {isBadged && (
        <div className={classNames('absolute right-0 top-0 inline-block size-2.5 rounded-full bg-error', className)} />
      )}
    </div>
  )
}
