import { CheckAltIcon } from '@/modules/shared/icons/CheckAltIcon'
import MinusAltIcon from '@/modules/shared/icons/MinusAltIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface CheckboxIconProps extends React.ComponentProps<'svg'> {
  isSelected: boolean
  isIndeterminate?: boolean
  disabled?: boolean
}

export function CheckboxIcon(props: CheckboxIconProps) {
  const { isSelected, isIndeterminate, disabled, ...restProps } = props

  const stroke = disabled && !isSelected ? 'gray-200' : 'white'

  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      className={classNames(
        'h-8 min-h-[2rem] w-8 min-w-[2rem] cursor-pointer transition duration-100 ease-in-out',
        disabled ? 'fill-gray-200' : isSelected || isIndeterminate ? 'fill-success' : 'fill-white'
      )}
      {...restProps}
    >
      <circle cx="12" cy="12" r="11" stroke="lightgray" strokeWidth="0.5"></circle>
      {isIndeterminate ? (
        <path d="M7 12H17.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      ) : (
        <path d="M7 13l3 3 7-7" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      )}
    </svg>
  )
}

interface SquareCheckboxIconProps extends React.ComponentProps<'div'> {
  isSelected: boolean
  isIndeterminate?: boolean
  disabled?: boolean
}

export function SquareCheckboxIcon(props: SquareCheckboxIconProps) {
  const { isSelected, isIndeterminate, disabled, ...restProps } = props

  return (
    <div
      className={classNames(
        'flex size-6 cursor-pointer items-center justify-center rounded-md border',
        disabled ? 'bg-gray-200' : isSelected || isIndeterminate ? 'bg-primary' : 'bg-white'
      )}
      {...restProps}
    >
      {isIndeterminate ? (
        <MinusAltIcon className="size-3 text-white" />
      ) : (
        <CheckAltIcon className="size-3 text-white" />
      )}
    </div>
  )
}
