export default function ClickIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path d="M17.67 15.33L14.88 12.54L16.8 11.43C17.01 11.31 17.14 11.07 17.12 10.83C17.1 10.58 16.95 10.37 16.72 10.28L7.4 6.56C7.16 6.47 6.89 6.52 6.71 6.7C6.53 6.88 6.47 7.15 6.57 7.39L10.29 16.71C10.38 16.94 10.6 17.09 10.84 17.11C11.07 17.13 11.32 17 11.44 16.79L12.55 14.87L15.34 17.66C15.46 17.78 15.63 17.85 15.79 17.85C15.95 17.85 16.12 17.78 16.24 17.66L17.67 16.23C17.92 15.98 17.92 15.57 17.67 15.32V15.33ZM13.2 12.32C13.17 12.52 13.24 12.72 13.38 12.86L16.31 15.79L15.79 16.31L12.86 13.38C12.74 13.26 12.58 13.19 12.41 13.19C12.38 13.19 12.35 13.19 12.33 13.19C12.13 13.22 11.96 13.33 11.86 13.51L10.99 15.01L8.32 8.31L15.02 10.98L13.52 11.85C13.35 11.95 13.23 12.13 13.2 12.32Z" />
      <path d="M5.65 4.74L4.56 3.65C4.31 3.4 3.9 3.4 3.65 3.65C3.53 3.77 3.46 3.93 3.46 4.1C3.46 4.27 3.53 4.43 3.65 4.56L4.74 5.65C4.86 5.77 5.02 5.84 5.19 5.84C5.36 5.84 5.52 5.77 5.64 5.65C5.89 5.4 5.89 4.99 5.64 4.74H5.65Z" />
      <path d="M4.97 7.16C4.97 6.81 4.68 6.52 4.33 6.52H2.78C2.43 6.52 2.14 6.81 2.14 7.16C2.14 7.51 2.43 7.8 2.78 7.8H4.33C4.68 7.8 4.97 7.51 4.97 7.16Z" />
      <path d="M4.52 8.57L3.43 9.66C3.31 9.78 3.24 9.94 3.24 10.12C3.24 10.29 3.31 10.45 3.43 10.57C3.55 10.69 3.71 10.76 3.88 10.76C4.05 10.76 4.21 10.69 4.33 10.57L5.42 9.48C5.54 9.36 5.61 9.2 5.61 9.02C5.61 8.84 5.54 8.69 5.42 8.57C5.18 8.32 4.75 8.33 4.51 8.57H4.52Z" />
      <path d="M7.16 4.97C7.51 4.97 7.8 4.68 7.8 4.33V2.78C7.8 2.43 7.51 2.14 7.16 2.14C6.81 2.14 6.52 2.43 6.52 2.78V4.33C6.52 4.68 6.81 4.97 7.16 4.97Z" />
      <path d="M9.03 5.61C9.2 5.61 9.36 5.54 9.48 5.42L10.57 4.33C10.69 4.21 10.76 4.05 10.76 3.87C10.76 3.69 10.69 3.54 10.57 3.42C10.45 3.3 10.29 3.23 10.12 3.23C9.95 3.23 9.79 3.3 9.67 3.42L8.58 4.51C8.46 4.63 8.39 4.79 8.39 4.96C8.39 5.13 8.46 5.29 8.58 5.42C8.7 5.54 8.86 5.61 9.03 5.61Z" />
    </svg>
  )
}
