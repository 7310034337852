export function NoteIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        d="M15.602 6.20679L11.9252 2.52959C11.7996 2.40271 11.6501 2.30201 11.4853 2.23335C11.3205 2.16468 11.1437 2.12942 10.9652 2.12959H6.0376C5.49711 2.13023 4.97897 2.34533 4.59694 2.72766C4.2149 3.10999 4.00021 3.62831 4 4.16879V15.8312C4.00074 16.3713 4.21567 16.8891 4.59764 17.271C4.97962 17.6529 5.49746 17.8678 6.0376 17.8684H13.9628C14.5029 17.8677 15.0206 17.6528 15.4025 17.2709C15.7844 16.889 15.9993 16.3713 16 15.8312V7.16719C16.0005 6.98874 15.9656 6.81197 15.8972 6.64711C15.8289 6.48225 15.7286 6.33259 15.602 6.20679V6.20679ZM14.64 15.8312C14.64 16.0115 14.5684 16.1845 14.4408 16.312C14.3133 16.4396 14.1403 16.5112 13.96 16.5112H6.0376C5.85725 16.5112 5.68429 16.4396 5.55677 16.312C5.42924 16.1845 5.3576 16.0115 5.3576 15.8312V4.16879C5.3576 3.98845 5.42924 3.81549 5.55677 3.68796C5.68429 3.56044 5.85725 3.48879 6.0376 3.48879H10.9648L14.64 7.16719V15.8312Z"
        fill="currentColor"
      />
      <path
        d="M10.6868 8.21601C10.6868 8.03385 10.6144 7.85916 10.4856 7.73036C10.3568 7.60156 10.1822 7.52921 10 7.52921C9.81785 7.52921 9.64316 7.60156 9.51436 7.73036C9.38556 7.85916 9.3132 8.03385 9.3132 8.21601V10.0708H7.4584C7.36679 10.0686 7.27566 10.0847 7.19037 10.1183C7.10509 10.1518 7.02737 10.202 6.9618 10.2661C6.89622 10.3301 6.84411 10.4065 6.80853 10.491C6.77295 10.5755 6.75462 10.6662 6.75462 10.7578C6.75462 10.8494 6.77295 10.9402 6.80853 11.0246C6.84411 11.1091 6.89622 11.1855 6.9618 11.2496C7.02737 11.3136 7.10509 11.3638 7.19037 11.3974C7.27566 11.4309 7.36679 11.447 7.4584 11.4448H9.3132V13.2996C9.3132 13.4818 9.38556 13.6564 9.51436 13.7852C9.64316 13.914 9.81785 13.9864 10 13.9864C10.1822 13.9864 10.3568 13.914 10.4856 13.7852C10.6144 13.6564 10.6868 13.4818 10.6868 13.2996V11.4448H12.5416C12.6332 11.447 12.7243 11.4309 12.8096 11.3974C12.8949 11.3638 12.9726 11.3136 13.0382 11.2496C13.1038 11.1855 13.1559 11.1091 13.1915 11.0246C13.2271 10.9402 13.2454 10.8494 13.2454 10.7578C13.2454 10.6662 13.2271 10.5755 13.1915 10.491C13.1559 10.4065 13.1038 10.3301 13.0382 10.2661C12.9726 10.202 12.8949 10.1518 12.8096 10.1183C12.7243 10.0847 12.6332 10.0686 12.5416 10.0708H10.6868V8.21601Z"
        fill="currentColor"
      />
    </svg>
  )
}
