export function PurchaserIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path d="M12.63,8.61H11.32a.8.8,0,0,0,0,1.6h1.31a.8.8,0,1,0,0-1.6Z" />
      <path d="M8.68,6H7.37a.8.8,0,0,0,0,1.6H8.68a.8.8,0,0,0,0-1.6Z" />
      <path d="M11.32,7.58h1.31a.8.8,0,0,0,0-1.6H11.32a.8.8,0,0,0,0,1.6Z" />
      <path d="M8.68,8.61H7.37a.8.8,0,0,0,0,1.6H8.68a.8.8,0,0,0,0-1.6Z" />
      <path d="M16.72,16.41V3.35a.8.8,0,0,0-.8-.8H4.08a.8.8,0,0,0-.8.8V16.41A.8.8,0,0,0,3.34,18H16.66a.8.8,0,0,0,.06-1.6ZM8.17,14.24a.18.18,0,0,1,.18-.18h3.3a.18.18,0,0,1,.18.18v2.17H8.17Zm3.48-1.78H8.35a1.78,1.78,0,0,0-1.78,1.78v2.17H4.88V4.15H15.12V16.41H13.43V14.24A1.78,1.78,0,0,0,11.65,12.46Z" />
    </svg>
  )
}
