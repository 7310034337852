export function Menu2Icon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path d="m14.16,1.79h-7.96c-1.49,0-2.7,1.21-2.7,2.7v.24h-.27c-.48,0-.86.39-.86.86s.39.86.86.86h.27v1.21h-.27c-.48,0-.86.39-.86.86s.39.86.86.86h.27v1.21h-.27c-.48,0-.86.39-.86.86s.39.86.86.86h.27v1.21h-.27c-.48,0-.86.39-.86.86s.39.86.86.86h.27v.24c0,1.49,1.21,2.7,2.7,2.7h7.96c1.49,0,2.7-1.21,2.7-2.7V4.49c0-1.49-1.21-2.7-2.7-2.7ZM5.22,15.27h.24c.48,0,.86-.39.86-.86s-.39-.86-.86-.86h-.24v-1.21h.24c.48,0,.86-.39.86-.86s-.39-.86-.86-.86h-.24v-1.21h.24c.48,0,.86-.39.86-.86s-.39-.86-.86-.86h-.24v-1.21h.24c.48,0,.86-.39.86-.86s-.39-.86-.86-.86h-.24v-.24c0-.54.44-.98.97-.98h7.96c.54,0,.97.44.97.98v11.02c0,.54-.44.97-.97.97h-7.96c-.54,0-.97-.44-.97-.97v-.24Z" />
    </svg>
  )
}
