export function BarcodeIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.68 7.6C3.11 7.6 3.46 7.25 3.46 6.82V4.52H5.76C6.19 4.52 6.54 4.17 6.54 3.74C6.54 3.31 6.19 2.96 5.76 2.96H2.68C2.25 2.96 1.9 3.31 1.9 3.74V6.82C1.9 7.25 2.25 7.6 2.68 7.6Z" />
      <path d="M17.32 2.95H14.24C13.81 2.95 13.46 3.3 13.46 3.73C13.46 4.16 13.81 4.51 14.24 4.51H16.54V6.81C16.54 7.24 16.89 7.59 17.32 7.59C17.75 7.59 18.1 7.24 18.1 6.81V3.73C18.1 3.3 17.75 2.95 17.32 2.95Z" />
      <path d="M5.76 15.48H3.46V13.18C3.46 12.75 3.11 12.4 2.68 12.4C2.25 12.4 1.9 12.75 1.9 13.18V16.26C1.9 16.69 2.25 17.04 2.68 17.04H5.76C6.19 17.04 6.54 16.69 6.54 16.26C6.54 15.83 6.19 15.48 5.76 15.48Z" />
      <path d="M17.32 12.4C16.89 12.4 16.54 12.75 16.54 13.18V15.48H14.24C13.81 15.48 13.46 15.83 13.46 16.26C13.46 16.69 13.81 17.04 14.24 17.04H17.32C17.75 17.04 18.1 16.69 18.1 16.26V13.18C18.1 12.75 17.75 12.4 17.32 12.4Z" />
      <path d="M11.32 6.34C10.88 6.34 10.51 6.7 10.51 7.15V12.86C10.51 13.3 10.87 13.67 11.32 13.67C11.77 13.67 12.13 13.31 12.13 12.86V7.15C12.13 6.71 11.77 6.34 11.32 6.34Z" />
      <path d="M8.68 6.34C8.24 6.34 7.87 6.7 7.87 7.15V12.86C7.87 13.3 8.23 13.67 8.68 13.67C9.13 13.67 9.49 13.31 9.49 12.86V7.15C9.49 6.71 9.13 6.34 8.68 6.34Z" />
      <path d="M14.75 12.86V7.15C14.75 6.71 14.39 6.34 13.94 6.34C13.49 6.34 13.13 6.7 13.13 7.15V12.86C13.13 13.3 13.49 13.67 13.94 13.67C14.39 13.67 14.75 13.31 14.75 12.86Z" />
      <path d="M6.05 6.34C5.61 6.34 5.24 6.7 5.24 7.15V12.86C5.24 13.3 5.6 13.67 6.05 13.67C6.5 13.67 6.86 13.31 6.86 12.86V7.15C6.86 6.71 6.5 6.34 6.05 6.34Z" />
    </svg>
  )
}
