export function CursorPointerIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path d="m17.31,15.29h0s-3-3-3-3l2.18-1.26c.14-.08.22-.23.21-.39-.01-.16-.11-.3-.26-.36l-9.32-3.72c-.15-.06-.33-.03-.45.09-.12.12-.15.29-.09.45l3.72,9.32c.06.15.2.25.36.26h.01c.15,0,.3-.09.37-.21l1.26-2.18,3,3c.08.08.18.12.3.12h0c.11,0,.22-.04.29-.12l1.43-1.43c.16-.16.16-.43,0-.59Zm-4.09-3.14c-.02.12.03.26.12.35l3.09,3.09-.84.84-3.09-3.09c-.08-.08-.18-.12-.3-.12h0c-.2.02-.3.12-.35.21l-1.1,1.9-3.04-7.62,7.62,3.04-1.9,1.1c-.11.07-.19.18-.21.31Z" />
      <path d="m4.6,4.02s0,0,0,0c-.16-.15-.43-.16-.59,0-.08.08-.12.18-.12.29s.04.22.12.3l1.09,1.1c.08.08.19.12.29.12s.22-.04.3-.12c.16-.16.16-.43,0-.59l-1.09-1.09Z" />
      <path d="m4.95,7.37c0-.23-.19-.42-.42-.42h-1.55c-.23,0-.42.19-.42.42s.19.42.42.42h1.55c.23,0,.42-.19.42-.42Z" />
      <path d="m5.18,8.82h0c-.11,0-.22.04-.29.12l-1.09,1.09c-.08.08-.12.18-.12.3s.04.21.12.29c.08.08.19.12.3.12.12,0,.22-.04.29-.12l1.09-1.09c.08-.08.12-.18.12-.3s-.04-.22-.12-.29c-.07-.07-.18-.12-.29-.12Z" />
      <path d="m7.37,4.96c.23,0,.42-.19.42-.42v-1.55c0-.23-.19-.42-.42-.42s-.42.19-.42.42v1.55c0,.23.19.42.42.42Z" />
      <path d="m9.23,5.59c.11,0,.22-.04.3-.12l1.09-1.09c.08-.08.12-.18.12-.3s-.04-.21-.12-.29c-.16-.16-.43-.16-.59,0l-1.1,1.1c-.08.08-.12.18-.12.29s.04.21.12.3c.08.08.19.12.29.12Z" />
    </svg>
  )
}
